import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/* 错误提示抑制 */
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};
/* 公共页面 */
import Layout from "@/components/Layout";
/* 路由配置 */
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login"),
    hidden: true,
  },
  {
    path: "/WeChat",
    component: () => import("@/views/login/WeChat"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    redirect: "/login", //空路由默认指向登陆页
    children: [
      {
        path: "index",
        name: "首页",
        component: () => import("@/views/index"),
        meta: {
          title: "首页",
          activePath: "/index",
        },
      },
      {
        path: "index/accurateInfo",
        name: "信息详情",
        component: () => import("@/views/index/component/accurateInfo"),
        meta: {
          title: "信息详情",
          activePath: "/index",
        },
      },
      {
        path: "index/accurateInfo/comment",
        name: "评论跟评4",
        component: () => import("@/views/index/component/comment"),
        meta: {
          title: "评论跟评",
          activePath: "/index",
        },
      },
      {
        path: "index/abroadInfo",
        name: "信息详情2",
        component: () => import("@/views/index/component/abroadInfo"),
        meta: {
          title: "信息详情",
          activePath: "/index",
        },
      },
      {
        path: "index/monitorInfo",
        name: "信息详情3",
        component: () => import("@/views/index/component/monitorInfo"),
        meta: {
          title: "信息详情",
          activePath: "/index",
        },
      },
      {
        path: "index/otherInfo",
        name: "其它信息",
        component: () => import("@/components/otherInfo"),
        meta: {
          title: "信息详情",
          activePath: "/index",
        },
      },
    ],
  },
  {
    path: "/message",
    component: Layout,
    redirect: "/message/index",
    children: [
      {
        path: "index",
        name: "实时信息",
        component: () => import("@/views/message"),
        meta: {
          title: "实时信息",
          activePath: "/message",
        },
      },
      {
        path: "index/original",
        name: "信息详情4",
        component: () => import("@/views/message/original.vue"),
        meta: {
          title: "信息详情",
          activePath: "/message",
        },
      },
    ],
  },
  {
    path: "/sentiment",
    component: Layout,
    redirect: "/sentiment/index",
    children: [
      {
        path: "index",
        name: "精准舆情",
        component: () => import("@/views/sentiment"),
        meta: {
          title: "精准舆情",
          activePath: "/sentiment",
        },
      },
      {
        path: "index/yuqing",
        name: "信息详情5",
        component: () => import("@/views/sentiment/yuqing.vue"),
        meta: {
          title: "信息详情",
          activePath: "/sentiment",
        },
      },
      {
        path: "index/yuqing/pinglun",
        name: "评论跟评2",
        component: () => import("@/views/sentiment/pinglun.vue"),
        meta: {
          title: "评论跟评",
          activePath: "/sentiment",
        },
      },
    ],
  },
  {
    path: "/videoModule",
    component: Layout,
    redirect: "/videoModule/index",
    children: [
      {
        path: "index",
        name: "视频监测",
        component: () => import("@/views/videoModule"),
        meta: {
          title: "视频监测",
          activePath: "/videoModule",
        },
      }
    ],
  },
  {
    path: "/sensitiveMessage",
    component: Layout,
    redirect: "/sensitiveMessage/index",
    children: [
      {
        path: "index",
        name: "境外敏感信息",
        component: () => import("@/views/sensitiveMessage"),
        meta: {
          title: "境外敏感信息",
          activePath: "/sensitiveMessage",
        },
      },
      {
        path: "index/xiangqing",
        name: "信息详情6",
        component: () => import("@/views/sensitiveMessage/xiangqing.vue"),
        meta: {
          title: "信息详情",
          activePath: "/sensitiveMessage",
        },
      },
    ],
  },
  {
    path: "/topics",
    component: Layout,
    redirect: "/topics/index",
    children: [
      {
        path: "index",
        name: "话题分析",
        component: () => import("@/views/topics"),
        meta: {
          title: "话题分析",
          activePath: "/topics",
        },
      },
      {
        path: "/topics/index/topicList",
        name: "历史话题",
        component: () => import("@/views/topics/topicList"),
        meta: {
          title: "历史话题",
          activePath: "/topics",
        },
      },
      {
        path: "/topics/index/topicList/topicListDetails",
        name: "话题详情",
        component: () => import("@/views/topics/topicListDetails"),
        meta: {
          title: "话题详情",
          activePath: "/topics",
        }
      },
      {
        path: "/topics/index/topicList/topicListDetails/getinfoMessage",
        name: "信息详情7",
        component: () => import("@/views/topics/getinfoMessage"),
        meta: {
          title: "信息详情",
          activePath: "/topics",
        },
      },
      {
        path: "/topics/index/topicList/topicListDetails/infoView",
        name: "境外话题信息详情",
        component: () => import("@/views/topics/infoView"),
        meta: {
          title: "话题分析",
          activePath: "/topics",
        },
      }
    ],
  },
  {
    path: "/report",
    component: Layout,
    redirect: "/report/index",
    children: [
      {
        path: "index",
        name: "舆情报告",
        component: () => import("@/views/report"),
        meta: {
          title: "舆情报告",
          activePath: "/report",
        },
      },
      {
        path: "index/baogao",
        name: "报告生成",
        component: () => import("@/views/report/baogao"),
        meta: {
          title: "报告生成",
          activePath: "/report",
        },
      },
      {
        path: "index/xiangqing",
        name: "报告详情",
        component: () => import("@/views/report/xiangqing"),
        meta: {
          title: "陕西省委网信办2021年01月21日舆情报告",
          activePath: "/report",
        },
      },
    ],
  },
  {
    path: "/analyse",
    component: Layout,
    redirect: "/analyse/index",
    children: [
      {
        path: "index",
        name: "综合分析",
        component: () => import("@/views/analyse"),
        meta: {
          title: "综合分析",
          activePath: "/analyse",
        },
      },
    ],
  },
  {
    path: "/monitoringScheme",
    component: Layout,
    redirect: "/monitoringScheme/index",
    children: [
      {
        path: "index",
        name: "监测方案",
        component: () => import("@/views/monitoringScheme"),
        meta: {
          title: "监测方案",
          activePath: "/monitoringScheme",
        },
        children: [
          {
            path: "/monitoringScheme/index/Territory",
            name: "境内",
            component: () => import("@/views/monitoringScheme/Territory"),
            meta: {
              title: "境内",
              activePath: "/monitoringScheme",
            },
          },
          {
            path: "/monitoringScheme/index/abroad",
            name: "境外",
            component: () => import("@/views/monitoringScheme/abroad"),
            meta: {
              title: "境外",
              activePath: "/monitoringScheme",
            },
          },
          {
            path: "/monitoringScheme/index/Follow",
            name: "跟评",
            component: () => import("@/views/monitoringScheme/Follow"),
            meta: {
              title: "跟评",
              activePath: "/monitoringScheme",
            },
          },
        ],
      },
      {
        path: "/monitoringScheme/index/detail",
        name: "境内详情页面",
        component: () => import("@/views/monitoringScheme/detail"),
        meta: {
          title: "详情页面",
          activePath: "/monitoringScheme",
        },
      },
      {
        path: "/monitoringScheme/index/detail2",
        name: "境外详情页面",
        component: () => import("@/views/monitoringScheme/detail2"),
        meta: {
          title: "详情页面",
          activePath: "/monitoringScheme",
        },
      },
    ],
  },
  {
    path: "/cloudSsearch",
    component: Layout,
    redirect: "/cloudSsearch/index",
    children: [
      {
        path: "index",
        name: "云搜索",
        component: () => import("@/views/cloudSsearch"),
        meta: {
          title: "云搜索",
          activePath: "/cloudSsearch",
        },
      },
    ],
  },
  {
    path: "/hotData",
    component: Layout,
    redirect: "/hotData/index",
    children: [
      {
        path: "index",
        name: "热榜数据",
        component: () => import("@/views/hotData"),
        meta: {
          title: "热榜数据",
          activePath: "/hotData",
        },
      },
      {
        path: "history",
        name: "热榜历史数据",
        component: () => import("@/views/hotData/history.vue"),
        meta: {
          title: "历史榜单",
          activePath: "/hotData",
        },
      },
    ],
  },
  {
    path: "/systemSettings",
    component: Layout,
    redirect: "/systemSettings/index",
    children: [
      {
        path: "index",
        name: "系统设置",
        component: () => import("@/views/systemSettings"),
        meta: {
          title: "系统设置",
          activePath: "/systemSettings",
        },
      },
      {
        path: "index/yuqing",
        name: "信息详情8",
        component: () => import("@/views/systemSettings/component/yuqing.vue"),
        meta: {
          title: "信息详情",
          activePath: "/systemSettings",
        },
      },
      {
        path: "index/yuqing/pinglun",
        name: "评论跟评3",
        component: () => import("@/views/systemSettings/component/pinglun.vue"),
        meta: {
          title: "评论跟评",
          activePath: "/systemSettings",
        },
      },
      {
        path: "index/xiangqing",
        name: "信息详情9",
        component: () =>
          import("@/views/systemSettings/component/xiangqing.vue"),
        meta: {
          title: "信息详情",
          activePath: "/systemSettings",
        },
      },
      {
        path: "index/original",
        name: "信息详情10",
        component: () =>
          import("@/views/systemSettings/component/original.vue"),
        meta: {
          title: "信息详情",
          activePath: "/systemSettings",
        },
      },
    ],
  },
  {
    path: "/dataCheck",
    component: Layout,
    redirect: "/dataCheck/index",
    children: [
      {
        path: "index",
        name: "数据检查",
        component: () => import("@/views/dataCheck"),
        meta: {
          title: "数据检查",
          activePath: "/dataCheck",
        },
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  // 404 page must be placed at the end !!!
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];
/* 路由跳转时 滚动条归0 */
const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  });
const router = createRouter();
router.beforeEach((to, from, next) => {
  // 判断是否已存在用户信息 如果有 但是后退到 /weChat || /login 默认为退出  否则正常跳转
  let getUser = JSON.parse(sessionStorage.getItem("user"));
  let loginState = getUser == null ? false : getUser.loginState;
  if (loginState) {
    if (to.path === "/login") {
      sessionStorage.clear();
      sessionStorage.setItem("user", JSON.stringify({ loginState: false }));
      next();
    } else if (to.path === "/WeChat") {
      if (JSON.parse(sessionStorage.getItem("user")).style != "business") {
        sessionStorage.clear();
        sessionStorage.setItem("user", JSON.stringify({ loginState: false }));
        next("/login");
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
