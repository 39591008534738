/*
 * @Author: gaojiahao 3055320775@qq.com
 * @Date: 2023-11-02 11:48:49
 * @LastEditors: gaojiahao 3055320775@qq.com
 * @LastEditTime: 2024-07-03 18:45:31
 * @FilePath: \konneShuJieCustomer\src\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import ElementUI from "element-ui";
import router from "../router";
const service = axios.create({
  // baseURL: "https://www.yuqing.cn/",
  baseURL: "https://test.yuqing.cn/",
  // baseURL: "https://test01.yuqing.cn",
});
service.all = axios.all;
service.interceptors.request.use(
  function (config) {
    // 一般在这个位置判断token是否存在
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
//响应拦截的风度回复
service.interceptors.response.use(
  function (response) {
    // 处理响应数据
    if (response.data.code === 200) {
      return Promise.resolve(response);
    } else if (response.data.code === 401) {
      ElementUI.Message({
        message: response.data.msg,
        type: "warning",
      });
      router.push({
        path: "/login",
      });
    } else if (response.data.code === 403 || response.data.code === 407) {
      router.push({
        path: "/login",
      });
    } else if (response.data.code === 400) {
      ElementUI.Message({
        message: response.data.msg,
        type: "warning",
      });
    } else {
      return response;
    }
  },
  function (error) {
    // 处理响应失败
    return Promise.reject(error);
  }
);
export default service;

